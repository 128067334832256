<template>
    <div class="Heater full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <navigation-left-small noBackButton ref="navLeft"/>
            <div class="content-area pa-5 flex-grow-1 bg-white">
                <v-form ref="form">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('system.heater.defaultTemp') }}</p>
                        </v-col>
                        <v-col class="col-2">
                            <text-input inputName="heaterSettings-defaultTemperature" @onInputFocus="onInputFocus"
                                        suffix="°C" onlyNumbers :readonly="!checkUserRights('systemHeaterSettingsEdit')"
                                        :class="{'noAction': !checkUserRights('systemHeaterSettingsEdit')}"
                                        :rules="[rules.limitTemp, rules.maxDecimalPlaces1]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('system.heater.testStart') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-select :items="selectTeststart" item-text="name" item-value="value"
                                      v-model="heaterSettings.testStart" class="heaterSettings-testStart-dropdown"
                                      outlined hide-details="auto" attach
                                      :readonly="!checkUserRights('systemHeaterSettingsEdit')"
                                      :class="{'noAction': !checkUserRights('systemHeaterSettingsEdit')}"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': heaterSettings.testStart != 1}">
                            <p>{{ $t('system.heater.fixedTemp') }}</p>
                        </v-col>
                        <v-col class="col-2">
                            <text-input inputName="heaterSettings-testStartOnFix" @onInputFocus="onInputFocus"
                                        suffix="°C" onlyNumbers :disabled="heaterSettings.testStart != 1"
                                        :readonly="!checkUserRights('systemHeaterSettingsEdit')"
                                        :class="{'noAction': !checkUserRights('systemHeaterSettingsEdit')}"
                                        :rules="[rules.testStart, rules.fixTemp, rules.maxDecimalPlaces1]"/>
                        </v-col>
                    </v-row>
                    <!--                    Funktion wird ev. in V2 wieder aktiviert-->
                    <!--                    <v-row class="d-flex align-center">-->
                    <!--                        <v-col class="col-2">-->
                    <!--                            <switch-on-off name="heaterSettings-offWhenFinished"-->
                    <!--                                           :disabled="!checkUserRights('systemHeaterSettingsEdit')"-->
                    <!--                                           :class="{'active': offWhenFinished}" class="mr-4"/>-->
                    <!--                        </v-col>-->
                    <!--                        <v-col class="col-10">-->
                    <!--                            <p>{{ $t('system.heater.offWhenFinished') }}</p>-->
                    <!--                        </v-col>-->
                    <!--                    </v-row>-->
                </v-form>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToSystem"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress"
                           :class="{'disabled': saveDisabled || !checkUserRights('systemHeaterSettingsEdit')}"
                           @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
//import SwitchOnOff from '@/components/SwitchOnOff.vue'
import FooterButton from '@/components/FooterButton.vue'
import NavigationLeftSmall from "@/components/NavigationLeftSmall.vue";

import {mapState} from "vuex";
import mixins from "@/mixins/mixins";
import Vue from "vue";

export default {
    name: 'Heater',
    components: {
        NavigationLeftSmall,
        TextInput,
        // SwitchOnOff,
        FooterButton,
    },
    props: {},
    data() {
        return {
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            moveOnCanceled: false,
            original: {},
            selectTeststart: [
                {
                    'name': this.$t('system.heater.testStartOnFix'),
                    'value': 1
                }, {
                    'name': this.$t('system.heater.testStartOnMean'),
                    'value': 2
                }
            ],
            rules: {
                limitTemp: v => {
                    if (v) {
                        return (parseFloat(v) >= 0.0 && parseFloat(v) <= 40.0) || `${this.$t('validation.minimum')}: 0.0°C, ${this.$t('validation.maximum')}: 40.0°C`;
                    } else return true;
                },
                testStart: v => {
                    if (this.heaterSettings.testStart != 1) {
                        return true;
                    } else {
                        return (this.heaterSettings.testStart == 1 && v != '') || `${this.$t('validation.required')}`;
                    }
                },
                fixTemp: v => {
                    if (v) {
                        if (this.heaterSettings.defaultTemperature) {
                            return (parseFloat(v) >= parseFloat(this.heaterSettings.defaultTemperature) && parseFloat(v) <= 40.0) || `${this.$t('validation.minimum')}: ${this.heaterSettings.defaultTemperature}°C, ${this.$t('validation.maximum')}: 40.0°C`;
                        } else {
                            return (parseFloat(v) >= 20 && parseFloat(v) <= 40.0) || `${this.$t('validation.minimum')}: 20°C, ${this.$t('validation.maximum')}: 40.0°C`;
                        }
                    } else return true;
                },
                maxDecimalPlaces1: v => {
                    if (v) {
                        let test = v.toString().split('.');
                        if (test[1]) {
                            if (test[1].length > 1) {
                                return `${this.$t('validation.chooseShorterDecimalPlace')}`;
                            }
                        }
                        return true;
                    } else return true;
                }
            }
        }
    },
    computed: {
        ...mapState([
            'heaterSettings',
            'activeStationId'
        ]),
        offWhenFinished() {
            return this.$store.state.heaterSettings.offWhenFinished;
        },
    },
    methods: {
        goToSystem() {
            this.$router.push('system');
        },
        save() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.showSaveProgress = true;
                let Heater = {};
                //optional
                if (this.heaterSettings.defaultTemperature != this.original.defaultTemperature && this.heaterSettings.defaultTemperature != "") {
                    Heater.defaultTemperature = parseFloat(this.heaterSettings.defaultTemperature);
                }
                if (this.heaterSettings.testStart != this.original.testStart) {
                    Heater.testStart = this.heaterSettings.testStart;
                }
                if (this.heaterSettings.testStartOnFix != this.original.testStartOnFix && this.heaterSettings.testStart === 1) {
                    Heater.testStartOnFix = parseFloat(this.heaterSettings.testStartOnFix);
                }
                if (this.heaterSettings.offWhenFinished != this.original.offWhenFinished) {
                    Heater.offWhenFinished = this.heaterSettings.offWhenFinished;
                }
                if (Object.keys(Heater).length === 0 && Heater.constructor === Object) {
                    this.showSaveProgress = false;
                    this.showSaveSuccess = true;
                    this.watcher();
                    setTimeout(() => {
                        this.saveDisabled = true;
                        this.showSaveSuccess = false;
                    }, 800)
                } else {
                    Heater.stationId = this.activeStationId;
                    this.$store.dispatch('postAxiosNoSetter', ['/disi/heating/settings', Heater])
                        .then(response => {
                            this.showSaveProgress = false;
                            if (response.status === 200) {
                                this.showSaveSuccess = true;
                                //https://v2.vuejs.org/v2/guide/reactivity.html#For-Arrays
                                //Vue.set(vm.items, indexOfItem, newValue)
                                Vue.set(this.heaterSettings, this.activeStationId, Heater)
                                this.$store.dispatch('getAxiosSetter', ['/disi/heating/settings', 'heaterlist']);
                                this.watcher();
                                setTimeout(() => {
                                    this.saveDisabled = true;
                                    this.showSaveSuccess = false;
                                }, 800)
                            }
                        })
                }
            }
        },
        watcher() {
            //copy heater settings to original for comparison
            this.original = JSON.parse(JSON.stringify(this.heaterSettings));

            let defaultTemperature = this.$watch('$store.state.heaterSettings.defaultTemperature', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let testStart = this.$watch('$store.state.heaterSettings.testStart', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let testStartOnFix = this.$watch('$store.state.heaterSettings.testStartOnFix', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            // let offWhenFinished = this.$watch('$store.state.heaterSettings.offWhenFinished', function () {
            //     this.saveDisabled = false;
            //     stopWatch();
            // });

            let stopWatch = function () {
                defaultTemperature();
                testStart();
                testStartOnFix();
                // offWhenFinished();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#' + input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/disi/heating/settings', 'heaterlist'])
            .then(response => {
                if (response.status === 200) {
                    //copy heater settings of active Station to heaterSettings
                    this.$store.commit('changeHeater');

                    //copy heater settings to original for comparison
                    this.original = JSON.parse(JSON.stringify(this.heaterSettings));

                    let defaultTemperature = this.$watch('$store.state.heaterSettings.defaultTemperature', function () {
                        this.saveDisabled = false;
                        stopWatch();
                    });

                    let testStart = this.$watch('$store.state.heaterSettings.testStart', function () {
                        this.saveDisabled = false;
                        stopWatch();
                    });

                    let testStartOnFix = this.$watch('$store.state.heaterSettings.testStartOnFix', function () {
                        this.saveDisabled = false;
                        stopWatch();
                    });

                    let offWhenFinished = this.$watch('$store.state.heaterSettings.offWhenFinished', function () {
                        this.saveDisabled = false;
                        stopWatch();
                    });

                    let stopWatch = function () {
                        defaultTemperature();
                        testStart();
                        testStartOnFix();
                        offWhenFinished();
                    }

                    this.$watch('$store.state.activeStationId', function () {
                        if (this.saveDisabled) {
                            stopWatch();
                            this.$store.commit('changeHeater');
                            //copy heater settings to original for comparison
                            this.original = JSON.parse(JSON.stringify(this.heaterSettings));

                            defaultTemperature = this.$watch('$store.state.heaterSettings.defaultTemperature', function () {
                                this.saveDisabled = false;
                                stopWatch();
                            });

                            testStart = this.$watch('$store.state.heaterSettings.testStart', function () {
                                this.saveDisabled = false;
                                stopWatch();
                            });

                            testStartOnFix = this.$watch('$store.state.heaterSettings.testStartOnFix', function () {
                                this.saveDisabled = false;
                                stopWatch();
                            });

                            offWhenFinished = this.$watch('$store.state.heaterSettings.offWhenFinished', function () {
                                this.saveDisabled = false;
                                stopWatch();
                            });
                        } else if (this.moveOnCanceled) {
                            this.moveOnCanceled = false;
                        } else {
                            this.$confirm({
                                message: this.$t('alerts.unsavedChanges'),
                                show: true,
                                cancelText: this.$t('general.cancel'),
                                continueText: this.$t('general.continue')
                            })
                                .then(() => {
                                    // continue
                                    stopWatch();
                                    this.saveDisabled = true;
                                    this.$store.commit('changeHeater');

                                    defaultTemperature = this.$watch('$store.state.heaterSettings.defaultTemperature', function () {
                                        this.saveDisabled = false;
                                        stopWatch();
                                    });

                                    testStart = this.$watch('$store.state.heaterSettings.testStart', function () {
                                        this.saveDisabled = false;
                                        stopWatch();
                                    });

                                    testStartOnFix = this.$watch('$store.state.heaterSettings.testStartOnFix', function () {
                                        this.saveDisabled = false;
                                        stopWatch();
                                    });

                                    offWhenFinished = this.$watch('$store.state.heaterSettings.offWhenFinished', function () {
                                        this.saveDisabled = false;
                                        stopWatch();
                                    });
                                })
                                .catch(() => {
                                    // cancel
                                    this.moveOnCanceled = true;
                                    this.$store.commit('changeStation', parseInt(this.original.stationId));
                                });
                        }

                    })
                }
            });
    },
    beforeRouteLeave(to, from, next) {
        if (this.saveDisabled) {
            next()
        } else {
            this.$confirm({
                message: this.$t('alerts.unsavedChanges'),
                show: true,
                cancelText: this.$t('general.cancel'),
                continueText: this.$t('general.continue')
            })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
</style>